/**
 * @file application.js
 * @desc Entry point of Application
 */

import initializer from "../modules/szfront_core/initializer";
import Analytics from "../modules/szfront_core/Analytics";
import CookiePolicy from "../modules/szfront_core/cookiePolicy";
import PerformanceMonitor from '../modules/szfront_core/PerformanceMonitor';
import interstitialObserver from "../modules/szfront_core/interstitialObserver";
import bannerObserver from "../modules/szfront_core/bannerObserver";
import "../layout/index";

/**
 * Run entire project
 * @type {{start: seezis.start}}
 */
let seezis = {
  start: function() {
    initializer.init();
  }
};

seezis.start();

let analytics = new Analytics({
  category: "Video",
  events: [
    "tags",
    "relatedId",
    "relatedPos",
    "trackOldBrowsers",
    "trackSideBarLinks",
    "trackSideBarOpen",
    "trackSearchBtn",
    "pwa",
    "playerAds",
    "pagesViewed2",
    "cookiesPolicy",
    "interstitialAd",
    "adsInpage",
  ],
  eventsPage: {
    page_main_index: {
      category: "Index",
      addEvents: ["trackSlider", "trackChannelLinks"]
    }
  },
  tagsEl: ".tag-label",
  playlistEl: ".playlist",
  relatedEl: ".video",
  channelEl: ".i-section__btn-link, .i-section__title a",
  sidebarEl: ".sidebar__item",
  sidebarBtn: ".topbar__side-menu-btn",
  sliderEl: ".m-slider__item",
  searchBtn: ".btn.btn-search"
});

analytics.init();

interstitialObserver();
bannerObserver('.bnr-analytics');

new CookiePolicy({
  banner: "cookie-banner",
  button: "cookie-btn",
  termsId: "Seeziskids Cookie Policy"
});

window.addEventListener('load', () => {
  if (!window.send_performance_data_to || !window.performance.getEntriesByName) return;

  let monitor = new PerformanceMonitor({
    measures: {
      measureRequestStart: ['requestStart'],
      measureResponseEnd: ['responseEnd'],
      measureDomLoading: ['domLoading'],
      measureDomInteractive: ['domInteractive'],
      measureDomContentLoadedEnd: ['domContentLoadedEventEnd'],
      measureDomComplete: ['domComplete'],

      // measureLoadPlayer: ['loadPlayerStart', 'loadPlayerEnd'],
    }
  });

  monitor.sendReport(window.send_performance_data_to);

  // console.log(monitor.report);

  // setTimeout(() => {
  //   monitor.addMeasure({ measureLoadEnd: ['loadEventEnd'] });
  //   PerformanceMonitor.showLog();
  //
  //   console.log(monitor.report);
  // }, 1000);
});

export { seezis };
