require("./index.scss");
import event from "../../modules/szfront_utils/event";
import Sidebar from "../../modules/szfront_core/Sidebar";

let sidebar = new Sidebar({
  selector: ".sidebar",
  inner: ".sidebar__inner",
  close: ".sidebar__close",
  saClassName: "sidebar--active",
  baClassName: "sb_active",
  text: ".sidebar__text",
  more: ".sidebar__text-more",
  moreActiveClassName: 'sidebar__text_active',
});

event.on("layout:sidebar_open", () => sidebar.show());
