import $on from "../szfront_utils/on";
import event from "../szfront_utils/event";
import sendGtag from "../szfront_core/sendGtag";
import getClosest from "../szfront_utils/getClosest";
import getCookie from "../szfront_utils/getCookie";
import setCookie from "../szfront_utils/setCookie";
import delayedEvents from "./delayedEvents";

/**
 * Send events to google Analytics
 * @module core/analytics
 * @type {{init, send, trackTags, trackRelatedPos, trackRelatedId, trackTopMenu, trackPageScroll, trackIndexPage, trackOldBrowsers, trackTimeOnPage}}
 */

class Analytics {
  constructor(options) {
    /**
     * Category For Google Analytics
     * @type {string}
     */
    this.category = options.category;

    /**
     * Events for Google Analytics
     * @type {[*]}
     */
    this.events = options.events;

    let pageType = document.body.getAttribute("data-init");

    for (let pageKey in options.eventsPage) {
      let pageObject = options.eventsPage[pageKey];

      if (pageType === pageKey) {
        this.category = pageObject.category;

        if (pageObject.events) {
          this.events = pageObject.events;
        }

        if (pageObject.addEvents) {
          this.addEvents(pageObject.addEvents);
        }
      }
    }

    /**
     * Tag button class
     * @type {string}
     */
    this.tagsEl = options.tagsEl;
    /**
     * Video block class
     * @type {string}
     */
    this.playlistEl = options.playlistEl;
    this.relatedEl = options.relatedEl;
    /**
     * Channel Element class
     * @type {string}
     */
    this.channelEl = options.channelEl;
    /**
     * Sidebar Element class
     * @type {string}
     */
    this.sidebarEl = options.sidebarEl;
    /**
     * Sidebar Activate button
     * @type {string}
     */
    this.sidebarBtn = options.sidebarBtn;
    /**
     * Main Slider item
     * @type {string}
     */
    this.sliderEl = options.sliderEl;
    /**
     * Header Search Button
     * @type {string}
     */
    this.searchBtn = options.searchBtn;
    /**
     * Description Button
     * @type {string}
     */
    this.descriptionBtn = options.descriptionBtn;
  }

  addEvents(events) {
    events.forEach(item => {
      this.events.push(item);
    });
  }

  /**
   * @param {String} eventName
   * @param callback
   */
  test(eventName, callback) {
    if (this.events.indexOf(eventName) >= 0) {
      callback();
    }
  }

  /**
   *
   * @param {Array} eventsList - Array with event names
   * @param {String} categoryName - Category name for GA
   */
  init(eventsList, categoryName) {
    if (typeof gtag === "undefined") {
      console.log("Enable gtag first!");
      return false;
    }

    this.events = eventsList || this.events;
    this.category = categoryName || this.category;

    //execute tracking methods
    this.test("tags", () => this.trackTags(this.tagsEl));
    this.test("relatedPos", () => this.trackRelatedPos(this.relatedEl));
    this.test("trackOldBrowsers", () => this.trackOldBrowsers());
    this.test("timeonpage", () => this.trackTimeOnPage());
    this.test("trackChannelLinks", () => this.trackSideBarLinks(this.channelEl, "Channel"));
    this.test("trackSideBarLinks", () => this.trackSideBarLinks(this.sidebarEl));
    this.test("trackSideBarOpen", () => this.trackSideBarOpen(this.sidebarBtn));
    this.test("trackSlider", () => this.trackSlider(this.sliderEl));
    this.test("trackSearchBtn", () => this.trackSearchBtn(this.searchBtn));
    this.test("trackDescriptionBtn", () => this.trackDescriptionBtn(this.descriptionBtn));
    this.test("desktopBannerInit", () => this.trackEvent("desktop-banner-init", "Desktop banner", "Init", 0));
    this.test("clickAds", () => this.trackClickAds());

    this.test("relatedId", () => {
      this.trackRelatedId();
      this.trackEvent("related-click", "related_click", undefined, 0);
      this.trackEvent("cl-related-click", "cl_related_click", undefined, 0);
    });

    this.test("pwa", () => {
      this.trackEvent("pwa-show-dialog", "Show dialog", undefined, 0, "PWA");
      this.trackEvent("pwa-installed", "Installed", undefined, 0, "PWA");
    });

    this.test("cookiesPolicy", () => {
      this.trackEvent("cookies-policy-show", "Show Cookies Policy", undefined, 0, "Index");
      this.trackEvent("cookies-policy-accept", "Accept Cookies Policy", undefined, 0, "Index");
    });

    this.test("score", () => {
      this.trackEvent("score-show-dialog", "Show dialog", "Show", 0, "Score");
      this.trackEvent("score-close-button", "Closed by Button", "Click", 0, "Score");
      this.trackEvent("score-close-outside", "Closed by Outside", "Click", 0, "Score");
      this.trackEvent("score-send-suggestions", "Send Suggestions", "Click", 0, "Score");
      this.trackEvent("score-rate", "Rate", "Click", 0, "Score");
    });

    this.test("scrollPlaylist", () => {
      this.trackEvent("scroll-playlist", "Scroll Playlist", "Scroll", 0);
    });

    this.test("playerAds", () => {
      this.trackEvent("adError", "adError", undefined, 0, "JwAds");
      this.trackEvent("adClick", "adClick", undefined, 0, "JwAds");
      this.trackEvent("adRequest", "vast_request", undefined, 0, "JwAds");
      this.trackEvent("adRequest", "invideo_loaded", undefined, 0, "JwAds");
      this.trackEvent("adRequest", "invideo_loaded_sess", undefined, 0, "JwAds");
      this.trackEvent("adRequestCl", "cl_invideo_loaded", undefined, 0, "JwAds");
      this.trackEvent("adComplete", "adComplete", undefined, 0, "JwAds");
      this.trackEvent("adImpression", "adImpression", undefined, 0, "JwAds");
    });

    this.test("pagesViewed2", () => {
      this.trackEvent("pages-viewed-2", "Pages viewed 2", window.location.pathname, 0);
    });

    this.test("interstitialAd", () => {
      this.trackEvent("interstitial-ad-show", "content_loaded", undefined, 0, "Index");
    });

    this.test("adsInpage", () => {
      this.trackEvent("ad-show", "adsInpage", undefined, 0, "Index");
      this.trackEvent("ad-action", undefined, undefined, 0, "Index");
    });

    this.test("prelandClicked", () => {
      this.trackEvent("preland-clicked", "first_click", undefined, 0, "Index");
    });

    this.test("userReturned", () => this.userReturned());
  }

  send(action, label, value, category = this.category) {
    sendGtag({
      action: action,
      label: label,
      value: value,
      category: category
    });

    try {
      this.aggregateEventValue(action, label);
    }
    catch(e) {
      // console.log(e);
    }
  }

  /**
   * Sends information about click on Tag
   * @param {String} selector
   */
  trackTags(selector) {
    let action = "Tags";
    let tags = document.querySelectorAll(selector);

    for (let i = 0, length = tags.length; i < length; i++) {
      $on(tags[i], "click", () => {
        let label = tags[i].innerText;
        this.send(action, label, 0);
      });
    }
  }

  /**
   * Sends Information about the position of the clicked video
   * @param {String} selector
   */
  trackRelatedPos(selector) {
    let action = "Related Position";
    let relatives = document.querySelectorAll(selector);

    for (let i = 0, length = relatives.length; i < length; i++) {
      $on(relatives[i], "click", () => {
        let label = i.toString();
        this.send(action, label, 0);
      });
    }
  }

  /**
   * Sends Information about the link of the clicked video
   * @param {String} selector
   */
  trackRelatedId() {
    const playlist = document.querySelector(this.playlistEl);

    if (playlist) {
      playlist.addEventListener('click', (ev) => {
        const El = getClosest(ev.target, this.relatedEl);
  
        if (El) {
          const label = El.querySelector('a').getAttribute('href');
          event.trigger("related-click", { label: label });
  
          if (getCookie('shownInterstitial'))
            event.trigger("cl-related-click", { label: label });
          else
            delayedEvents().add({name: "cl-related-click", detail: { label: label }})
        }
      })
    }
  }

  /**
   * Sends Information about the link of the clicked sidebar item
   * @param {String} selector
   * @param {String} action
   */
  trackSideBarLinks(selector, action = "Sidebar") {
    let els = document.querySelectorAll(selector);

    for (let i = 0, length = els.length; i < length; i++) {
      $on(els[i], "click", () => {
        let label = els[i].getAttribute("href");
        this.send(action, label, 0);
      });
    }
  }

  trackSideBarOpen(selector) {
    let btn = document.querySelector(selector);
    let action = "Sidebar open";

    $on(btn, "click", () => {
      let label = "Click";
      this.send(action, label, 0);
    });
  }

  trackSlider(selector) {
    let action = "Slider";
    let els = document.querySelectorAll(selector);
    for (let i = 0, length = els.length; i < length; i++) {
      $on(els[i], "click", () => {
        let label = els[i].getAttribute("href");
        this.send(action, label, 0);
      });
    }
  }

  trackSearchBtn(selector) {
    let btn = document.querySelector(selector);
    let action = "Search Button";

    $on(btn, "click", () => {
      let label = "Click";
      this.send(action, label, 0);
    });
  }

  trackDescriptionBtn(selector) {
    let btn = document.querySelector(selector);
    if (!btn) return false;

    $on(btn, "click", () => {
      let action = `${btn.dataset.prev} Button`;
      let label = "Click";
      this.send(action, label, 0);
    });
  }

  /**
   * Tracks old Webkits with HLS problems
   */
  trackOldBrowsers() {
    const oldWebkit = 534.3;
    let webKitVersion = parseFloat(/\d{3}\.\d{2}(?!AppleWebKit\/)/.exec(navigator.userAgent));
    if (webKitVersion <= oldWebkit) {
      this.send("Old WebKit", webKitVersion.toString(), 1);
    }
  }

  /**
   * Tracks time on page
   */
  trackTimeOnPage() {
    let interval = 20000; //10sec
    let newInterval = 30000; //30 sec;
    let timer = 0;

    let sendAnalytics = () => {
      let oldTimer = timer;
      if (timer < 60) {
        timer += interval / 1000;
      } else {
        timer += newInterval / 1000;
      }

      let label = oldTimer + "-" + timer + " seconds";
      this.send("timer", label, 1);
      if (timer === 60) {
        clearInterval(time);
        time = setInterval(sendAnalytics, newInterval);
      }
      if (timer > 600) {
        clearInterval(time);
      }
    };

    let time = setInterval(sendAnalytics, interval);
  }

  trackClickAds() {
    let label = "noname";

    const openNewTab = () => {
      if (document.visibilityState === 'hidden') {
        sendEv();
      }
    }

    const changeNav = () => {
      localStorage.setItem("szUserLeft", Date.now());
      sendEv();
    }

    const sendEv = () => {
      this.send("content_clicked", label, 0, 'Index');
    }

    window.addEventListener('blur', () => {
      if (document.activeElement && document.activeElement.tagName === 'IFRAME') {

        let gb = getClosest(document.activeElement, '.bnr-analytics');
        let gi = getClosest(document.activeElement, '[data-vignette-loaded="true"]');
        let gv = getClosest(document.activeElement, '.szp__ad');

        if (gb) label = gb.dataset.slotname;
        else if (gi) label = 'interstitial';
        else if (gv) label = 'vast';
        else label = "noname";

        document.addEventListener("visibilitychange", openNewTab);
        window.addEventListener('beforeunload', changeNav);

        setTimeout(() => {
            window.focus();
            document.removeEventListener("visibilitychange", openNewTab);
            window.removeEventListener('beforeunload', changeNav);
        }, 250);
      }
    });
  }

  userReturned() {
    window.addEventListener('pageshow', () => {
      const userLeft = +localStorage.getItem("szUserLeft");
      const timePassed = (Date.now() - userLeft)/1000;
      if (userLeft && timePassed > 30 && timePassed < 600) {
        this.send("User returned", undefined, 0, 'Index');
      } else {
        localStorage.removeItem("szUserLeft");
      }
    });
  }

  trackEvent(eventType, GAeventName, GAlabel, GAvalue, GAcategory) {
    event.on(eventType, ({ detail }) => {
      const resLabel = detail && "label" in detail ? detail.label : GAlabel;
      const resAction = detail && "action" in detail ? detail.action : GAeventName;
      this.send(resAction, resLabel, GAvalue, GAcategory);
    });
  }

  aggregateEventValue(action, label) {
    if(!window.hasOwnProperty('event_values')) { return; }
    var ev = window.event_values;
    var value = 0.0;
    var aggregated_value = 0.0;

    var keys = [action, action + ':' + label];
    keys.forEach(function(key){
      if(key in ev) {value += ev[key];}
    });

    if(value > 0.0) {
      var aggregated_value = getCookie('EVV') || 0.0;
      aggregated_value = parseFloat(aggregated_value);
      aggregated_value += value;
      setCookie('EVV', aggregated_value, 0.042); /// 1 hour ~= 0.042 days

      var target_value = ev['target_value'] || 0.0;
      if(target_value <= aggregated_value){
        sendGtag({action: 'mission_accomplished', label: '', value: parseInt(value*10000), category: this.category});
      }
      if(target_value*2 <= aggregated_value){
        sendGtag({action: 'mission_x2_accomplished', label: '', value: parseInt(value*10000), category: this.category});
      }
      if(target_value*4 <= aggregated_value){
        sendGtag({action: 'mission_x4_accomplished', label: '', value: parseInt(value*10000), category: this.category});
      }
    }

  }
}

export default Analytics;
