require("./index.scss");
import event from "../../modules/szfront_utils/event";
import $on from "../../modules/szfront_utils/on";
import SearchForm from "../../modules/szfront_core/SearchForm";
import Modal from "../../modules/szfront_core/Modal";
import langHandler from "../../modules/szfront_core/langHandler";

new SearchForm({
  btnSelector: ".btn-search",
  btnActive: "btn-search--active",

  formSelector: ".topbar__search-form",
  formActive: "topbar__search-form--active",

  inputSelector: "#top_search",
  innerSelector: ".topbar__search-form-inner",
  labelSelector: ".topbar__search-form-label"
});

let sidebarOpenBtn = document.querySelector(".topbar__side-menu-btn");
$on(sidebarOpenBtn, "click", function() {
  event.trigger("layout:sidebar_open");
});

new Modal({
  button: ".btn-lang-selector",
  modal: ".lng-selector"
});

langHandler(".lng-selector a");
