class Modal {
  constructor(options) {
    this.button = document.querySelector(options.button);
    this.modal = document.querySelector(options.modal);
    this.modalActiveClass = options.modal.slice(1) + (options.active || "--active");
    this.margin = options.margin || 30;
    this.breakpointForMobile = options.breakpoint || `(max-width: 600px)`;
    /**
     * @see https://developer.mozilla.org/ru/docs/Web/API/EventTarget/addEventListener#The_value_of_this_within_the_handler
     */
    this.outsideWithBind = this.outsideClick.bind(this);

    this.button.addEventListener("click", () => {
      return this.modal.classList.contains(this.modalActiveClass) ? this.closeModal() : this.showModal();
    });
  }

  outsideClick(ev) {
    if (!this.modal.contains(ev.target)) {
      ev.preventDefault();
      this.closeModal();
    }
  }

  showModal() {
    this.calcPosition();
    this.modal.classList.add(this.modalActiveClass);

    setTimeout(() => {
      document.body.addEventListener("click", this.outsideWithBind);
    }, 100);
  }

  closeModal() {
    this.modal.classList.remove(this.modalActiveClass);
    document.body.removeEventListener("click", this.outsideWithBind);
  }

  calcPosition() {
    let btnPos = this.button.getBoundingClientRect();
    this.modal.style.top = btnPos.bottom + this.margin + "px";
    if (window.matchMedia(this.breakpointForMobile).matches) {
      this.modal.style.left = (window.innerWidth - this.modal.offsetWidth) / 2 + "px";
    } else {
      this.modal.style.left = btnPos.right - this.modal.offsetWidth + "px";
    }
  }
}
export default Modal;
